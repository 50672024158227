<template>
  <div v-if="emptyShow">
    <div class="contract_border"
         v-for="item in newList"
         :key="item.id">
      <div class="contract_top">
        <span>{{ item.name }}</span>
        <span class="clickColor"
              @click="goDetail({ code: item.code })">查看详情</span>
      </div>
      <el-descriptions class="margin-top"
                       style="margin-top: 20px;padding: 10px;"
                       :column="3">
        <el-descriptions-item label="合同分类">
          <span v-if="item.type === '1'">入职合同</span>
          <span v-if="item.type === '2'">离职合同</span>
          <span v-if="item.type==='3'">其他</span>
        </el-descriptions-item>
        <el-descriptions-item label="签署状态">
          <span class="Authenticated"
                v-if="item.signFlowStatus==='2'">签署完成</span>
          <span class="Authenticated"
                v-if="item.signFlowStatus==='3'">已撤销</span>
          <span class="isAuthenticated"
                v-if="item.signFlowStatus==='7'">已拒签</span>
          <span class="isAuthenticated"
                v-if="item.signFlowStatus==='104'">审核不通过</span>
          <span class="isAuthenticated"
                v-if="item.signFlowStatus==='105'">待企业签署</span>
          <span class="isAuthenticated"
                v-if="item.signFlowStatus==='5'">已过期</span>
          <span class="isAuthenticated"
                v-if="item.signFlowStatus==='100'">待发起</span>
          <span class="isAuthenticated"
                v-if="item.signFlowStatus==='103'">已完成</span>
          <span class="isAuthenticated"
                v-if="item.signFlowStatus==='102'">已解约</span>
          <span class="isAuthenticated"
                v-if="item.signFlowStatus==='101'">签署中</span>
        </el-descriptions-item>

        <el-descriptions-item label="甲方">
          <span>{{ item.subjectNames[0] }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="甲方签署人">
          <span>{{ item.signerNames[0] }}</span>
        </el-descriptions-item>

        <el-descriptions-item label="乙方">
          <span>{{ item.subjectNames[1] }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="乙方签署人">
          <span>{{ item.signerNames[1] }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="截止签署日期">
          <span>{{ item.createTime | dateVal }}</span>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <addDialog :dialogVisible.sync="addDialogVisible"
               @close="close()"></addDialog>
  </div>
  <el-empty v-else
            :image-size="200"
            :description="title"></el-empty>
</template>
<script>

import { api } from '/src/api/base';
export const contractList = api()('staff.info.json');
export default {
  props: {
    list: Array,
    bankFormVisible: Boolean,
  },
  components: {
    addDialog: () => import('../contract/Add'),
  },
  data () {
    return {
      title: '暂无合同',
      addDialogVisible: false,
      newList: [],
      emptyShow: false
    }
  },
  watch: {
    list (newValue, oldValue) {
      if (newValue.length != 0) {
        this.newList = this.list
        this.emptyShow = true
        this.loading()
      }
      else {
        this.emptyShow = false
      }
      console.log(newValue, oldValue, 2);
    }
  },
  mounted () {
    this.loading()
  },
  methods: {
    goDetail (obj) {
      this.$router.push({
        path: '/system/contract/detail',
        query: obj,
      });
    },
    loading () {
      this.newList = this.newList.map(it => {
        var subjectNames = []
        var signerNames = []
        for (let index = 0; index < it.singers.length; index++) {
          if (it.singers[index].orgName === '') {
            subjectNames.push(it.singers[index].psnName)
            signerNames.push(it.singers[index].psnName)
          }
          if (it.singers[index].orgName != '') {
            subjectNames.push(it.singers[index].orgName)
            signerNames.push(it.singers[index].psnName)
          }
        }

        return {
          ...it,
          subjectNames: subjectNames,
          signerNames: signerNames
        }
      })

    },

    addObject () {
      this.addDialogVisible = true;
    },
  },
}
</script>
<style lang="scss" scoped>
.contract_border {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  margin-bottom: 20px;

  .contract_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f2f2f2;
    padding: 10px;

    span:nth-child(1) {
      font-weight: 700;
    }

    span:nth-child(2) {
      font-size: 13px;
    }
  }
}
</style>